import React from 'react';
import { Helmet } from 'react-helmet-async';

// Dinamik SEO Konfigürasyonu
const SEOConfig = ({ title, description, url, image, type = 'website', keywords = '' }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "${type === 'profile' ? 'Person' : 'WebPage'}",
            "name": "${title}",
            "url": "${url}",
            "description": "${description}",
            "image": "${image}"
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEOConfig;
